//전역 변수
export const state = {
    logged_info: {},
    authentication: false,
}
//전역 computed 사용할 때 쓰는 method
export const getters = {
    print_logged_info(state) {
        /*console.log(state.logged_info)
        console.log(state.authentication)*/
        return state.logged_info
    },
    checkAuth(state) {
        return state.authentication
    }
}
//mutations == vue component method
export const mutations = {
    setLoggedInfo(state, logged_info_obj) {
        state.logged_info = logged_info_obj
        // state.logged_info.customevent = 'Y'
    },
    setInitInfo(state) {
        /*console.log('setTnitInfo')*/
        state.authentication = false
        state.logged_info = null
    },
    setAuth(state, chk) {
        state.authentication = chk
    },
}
//mutations + 비동기적인 함수 사용가능 (상태 변경은 무조건 mutations에서)
export const actions = {}

export const modules = {}
