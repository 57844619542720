import {mapActions, mapMutations, mapState} from "vuex";

export const evtProdStore = {
    ...mapState('eventProductStore', {
        barcodeList: (state) => state.barcodeList,
        selectedBarcode: (state) => state.selectedBarcode,
    })
}

export const evtProdMutations = mapMutations('eventProductStore', ["setBarcodeData"])

export const evtProdAction = mapActions('eventProductStore', ["callBarcodeListSrch"])
