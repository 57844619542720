import { mapActions, mapState } from "vuex";
import {
  signUpObjActList,
  signUpValidActList
} from "@/state/modules/signUp/actions";

export const signUpComputed = {
  ...mapState("signUp", {
    signUpObj: state => state.signUpObj,
    signUpValid: state => state.signUpValid
  })
};
export const signUpMethods = mapActions("signUp", [
  ...signUpObjActList,
  ...signUpValidActList
]);
