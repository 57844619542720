//전역 변수
import axios from "axios";

export const state = {
    postBarcode: {
        category: '',
        product_name: '',
        phone: '',
        img: '',
    },
    srchBarcodeList: [],
    initChk: '',
}

//전역 computed 사용할 때 쓰는 method
export const getters = {}

//mutations == vue component method
export const mutations = {
    setPostBarcode(state, payload) {
        // console.log(state, payload)
        if (payload.chk === 'cat') {
            state.postBarcode.category = payload.val
        } else if (payload.chk === 'pd') {
            state.postBarcode.product_name = payload.val
        } else if (payload.chk === 'phone' ) {
            state.postBarcode.phone = payload.val
        } else if (payload.chk === 'img') {
            state.postBarcode.img = payload.val
        }

        //console.log('postBarcode : ', state.postBarcode)
    },

    setBarcodeList(state, payload) {
        state.srchBarcodeList = payload
    },

    chkSuccess(state, val) {
        state.initChk = val
    }
}

//mutations + 비동기적인 함수 사용가능 (상태 변경은 무조건 mutations에서)
export const actions = {
    async srchBarcode({commit}, keyword) {
        console.log(keyword)

        let url = `/api/posbarcode/items?qu=${keyword}`

        await axios.get(url)
            .then((res) => {
                console.log(res.data)

                commit('setBarcodeList', res.data)
            })
            .catch(
                err => console.log(err)
            )

        console.log(state)
    },

    async postBarcodeAct({commit}, payload) {
        console.log(payload)
        let url = `/api/posbarcode/${payload.biznum}`

        let fd = new FormData()
        fd.append('product_name', payload.barcodeData.product_name)
        fd.append('category', payload.barcodeData.category)
        fd.append('img', payload.barcodeData.img)
        fd.append('phone', payload.barcodeData.phone)

        console.log(fd)

        await axios.post(url, fd)
            .then((res) => {
                console.log('res', res.data)

                if (res.data.status == 'success') {
                    commit('chkSuccess', 'success')
                } else {
                    commit('chkSuccess', 'fail')
                }
            })
            .catch(err => console.log(err))
    }
}

export const modules = {}
