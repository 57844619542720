export const signUpObjMuts = {
  setBusinessTypeMut(state, payload) {
    state.signUpObj.business_type = payload;
  },
  setBizNumMut(state, payload) {
    state.signUpObj.biznum = payload;
  },
  setPwMut(state, payload) {
    state.signUpObj.pw = payload;
  },
  setMartNameMut(state, payload) {
    state.signUpObj.martname = payload;
  },
  setTelMut(state, payload) {
    state.signUpObj.tel = payload;
  },
  setStimeMut(state, payload) {
    state.signUpObj.stime = payload;
  },
  setEtimeMut(state, payload) {
    state.signUpObj.etime = payload;
  },
  setCeonameMut(state, payload) {
    state.signUpObj.ceoname = payload;
  },
  setPhoneMut(state, payload) {
    state.signUpObj.phone = payload;
  },
  setImpUidMut(state, payload) {
    state.signUpObj.imp_uid = payload;
  },
  setAddrMut(state, payload) {
    state.signUpObj.addr = payload;
  },
  setAddrDetailMut(state, payload) {
    state.signUpObj.addr_detail = payload;
  },
  setWidoMut(state, payload) {
    state.signUpObj.wido = payload;
  },
  setGyeongdoMut(state, payload) {
    state.signUpObj.gyeongdo = payload;
  },
  resetAllMut(state, payload) {
    state.signUpObj.business_type = payload;
    state.signUpObj.biznum = payload;
    state.signUpObj.pw = payload;
    state.signUpObj.martname = payload;
    state.signUpObj.tel = payload;
    state.signUpObj.stime = payload;
    state.signUpObj.etime = payload;
    state.signUpObj.ceoname = payload;
    state.signUpObj.phone = payload;
    state.signUpObj.imp_uid = payload;
    state.signUpObj.addr = payload;
    state.signUpObj.addr_detail = payload;
    state.signUpObj.wido = payload;
    state.signUpObj.gyeongdo = payload;
  }
};
export const signUpValidMuts = {
  setBusinessTypeValidMut(state, payload) {
    state.signUpValid.business_type = payload;
  },
  setBizNumValidMut(state, payload) {
    state.signUpValid.biznum = payload;
  },
  setPwValidMut(state, payload) {
    state.signUpValid.pw = payload;
  },
  setMartNameValidMut(state, payload) {
    state.signUpValid.martname = payload;
  },
  setTelValidMut(state, payload) {
    state.signUpValid.tel = payload;
  },
  setStimeValidMut(state, payload) {
    state.signUpValid.stime = payload;
  },
  setEtimeValidMut(state, payload) {
    state.signUpValid.etime = payload;
  },
  setCeonameValidMut(state, payload) {
    state.signUpValid.ceoname = payload;
  },
  setPhoneValidMut(state, payload) {
    state.signUpValid.phone = payload;
  },
  setImpUidValidMut(state, payload) {
    state.signUpValid.imp_uid = payload;
  },
  setAddrValidMut(state, payload) {
    state.signUpValid.addr = payload;
  },
  setAddrDetailValidMut(state, payload) {
    state.signUpValid.addr_detail = payload;
  },
  setSubmitMut(state, payload) {
    state.signUpValid.submit = payload;
  }
};
