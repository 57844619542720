//전역 변수
export const state = {
    appGroupName: [],
}
//전역 computed 사용할 때 쓰는 method
export const getters = {
    printGroupName(state) {
        //console.log(state.appGroupName)
        return state.appGroupName
    },
}
//mutations == vue component method
export const mutations = {
    setGroupList(state, groupName) {
        //console.log('setGroupName', groupName)

        state.appGroupName = groupName
    }
}
//mutations + 비동기적인 함수 사용가능 (상태 변경은 무조건 mutations에서)
export const actions = {}

export const modules = {}
