//import store from '@/state/store'

export default [
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/product/index")
  },
  {
    path: "/login",
    name: "login",
    meta: {
      authRequired: false
    },
    component: () => import("../views/pages/account/login_new")
  },
  {
    path: "/signup_biz",
    name: "signup_biz",
    meta: {
      authRequired: false
    },
    component: () => import("../views/pages/account/signup/signup_biz.vue")
  },
  {
    path: "/signup",
    name: "signup",
    meta: {
      authRequired: false
    },
    component: () => import("../views/pages/account/signup/signup.vue")
  },
  {
    path: "/auth/myinfo",
    name: "myinfo",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/account/myInfo/myinfo.vue")
  },
  /*{
        path: '/product-board',
        name: 'product-board',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/product/index')
    },*/
  {
    path: "/flyers",
    name: "flyers",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/flyers/index")
  },
  {
    path: "/verticalFlyers",
    name: "verticalFlyers",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/verticalFlyers/VerticalFlyers")
  },
  {
    path: "/verticalFlyers/verticalImageManager",
    name: "verticalImageManager",
    meta: {
      authRequired: true
    },
    component: () =>
      import("../views/pages/verticalFlyers/VerticalImageManager"),
    props: true
  },
  {
    path: "/flyer/ImageManager",
    name: "ImageManager",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/flyers/ImageManager"),
    props: true
  },
  {
    path: "/issue_coupon",
    name: "issuecoupon",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/coupon/coupon")
  },
  {
    path: "/coupon_list",
    name: "couponlist",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/coupon/coupon_list")
  },
  {
    path: "/voc",
    name: "voiceOfCustomers",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/voc/voc")
  },
  {
    path: "/macltalk",
    name: "macltalk",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/maclTalk/sendTalk")
  },
  {
    path: "/pushmsg",
    name: "pushmsg",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/pushmsg/pushmsg")
  },
  {
    path: "/eventlist",
    name: "eventlist",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/event/eventlist")
  },
  {
    path: "/eventoriginlist",
    name: "eventOriginList",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/eventOrigin/eventlistOrigin.vue")
  },
  {
    path: "/404",
    name: "notFound",
    /*meta: {
          authRequired: false
        },*/
    component: () => import("../views/pages/errors/404-basic")
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/talk/mngnewmembers",
    name: "mngNewMembers",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/maclTalk/mngNewMembers")
  },
  {
    path: "/talk/kakaonewmembers",
    name: "kakaoNewMembers",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/kakaoTalk/kakaoNewMembers")
  },
  {
    path: "/talk/sendtalk",
    name: "sendKakao",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/kakaoTalk/sendKakaoTalk")
  },
  {
    path: "/talk/mngTemplete",
    name: "templateManagement",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/kakaoTalk/templateManagement")
  },
  {
    path: "/talk/history",
    name: "talkHistory",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/kakaoTalk/talkHistory")
  },
  {
    path: "/talk/mngdeposit",
    name: "pointManagement",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/kakaoTalk/pointManagement")
  },
  {
    path: "/talk/history/macltalk",
    name: "maclTalkHistory",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/maclTalk/maclTalkHistory")
  },
  {
    path: "/talk/customer/groups",
    name: "groupManagement",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/maclTalk/groupManagement")
  },
  {
    path: "/bestproduct",
    name: "bestproduct",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/bestProduct/bestList.vue")
  },
  {
    path: "/talk/viewshistory",
    name: "viewshistory",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/maclTalk/viewsHistory")
  },
  {
    path: "/barcode",
    name: "custombarcode",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/cusomBarcode/index")
  },
  {
    path: "/guide",
    name: "guide",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/guide/guideMain")
  },
  {
    path: "/testhtml",
    name: "testHtml",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/testHtml")
  }
];
