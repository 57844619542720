import {mapActions, mapMutations, mapState} from "vuex";

export const customBarcodeState = {
    ...mapState('customBarcodeStore', {
        postBarcode: (state) => state.postBarcode,
        srchBarcodeList: (state) => state.srchBarcodeList,
        initChk: (state) => state.initChk
    })
}

export const customBarcodeMutation = mapMutations('customBarcodeStore', ["setPostBarcode", 'chkSuccess'])

export const customBarcodeAction = mapActions('customBarcodeStore', ["srchBarcode", "postBarcodeAct"])
