export const signUpObjActs = {
  setBusinessTypeAct({ commit }, { payload }) {
    commit("setBusinessTypeMut", payload);
  },
  setBizNumAct({ commit }, { payload }) {
    commit("setBizNumMut", payload);
  },
  setPwAct({ commit }, { payload }) {
    commit("setPwMut", payload);
  },
  setMartNameAct({ commit }, { payload }) {
    commit("setMartNameMut", payload);
  },
  setTelAct({ commit }, { payload }) {
    commit("setTelMut", payload);
  },
  setStimeAct({ commit }, { payload }) {
    commit("setStimeMut", payload);
  },
  setEtimeAct({ commit }, { payload }) {
    commit("setEtimeMut", payload);
  },
  setCeonameAct({ commit }, { payload }) {
    commit("setCeonameMut", payload);
  },
  setPhoneAct({ commit }, { payload }) {
    commit("setPhoneMut", payload);
  },
  setImpUidAct({ commit }, { payload }) {
    commit("setImpUidMut", payload);
  },
  setAddrAct({ commit }, { payload }) {
    commit("setAddrMut", payload);
  },
  setAddrDetailAct({ commit }, { payload }) {
    commit("setAddrDetailMut", payload);
  },
  setWidoAct({ commit }, { payload }) {
    commit("setWidoMut", payload);
  },
  setGyeongdoAct({ commit }, { payload }) {
    commit("setGyeongdoMut", payload);
  },
  resetAllAct({ commit }) {
    commit("resetAllMut", "");
  }
};
export const signUpObjActList = [
  "setBusinessTypeAct",
  "setBizNumAct",
  "setPwAct",
  "setMartNameAct",
  "setTelAct",
  "setStimeAct",
  "setEtimeAct",
  "setCeonameAct",
  "setPhoneAct",
  "setImpUidAct",
  "setAddrAct",
  "setAddrDetailAct",
  "setWidoAct",
  "setGyeongdoAct",
  "resetAllAct"
];
export const signUpValidActs = {
  setBusinessTypeValidAct({ commit }, { payload }) {
    commit("setBusinessTypeValidMut", payload);
  },
  setBizNumValidAct({ commit }, { payload }) {
    commit("setBizNumValidMut", payload);
  },
  setPwValidAct({ commit }, { payload }) {
    commit("setPwValidMut", payload);
  },
  setMartNameValidAct({ commit }, { payload }) {
    commit("setMartNameValidMut", payload);
  },
  setTelValidAct({ commit }, { payload }) {
    commit("setTelValidMut", payload);
  },
  setStimeValidAct({ commit }, { payload }) {
    commit("setStimeValidMut", payload);
  },
  setEtimeValidAct({ commit }, { payload }) {
    commit("setEtimeValidMut", payload);
  },
  setCeonameValidAct({ commit }, { payload }) {
    commit("setCeonameValidMut", payload);
  },
  setPhoneValidAct({ commit }, { payload }) {
    commit("setPhoneValidMut", payload);
  },
  setImpUidValidAct({ commit }, { payload }) {
    commit("setImpUidValidMut", payload);
  },
  setAddrValidAct({ commit }, { payload }) {
    commit("setAddrValidMut", payload);
  },
  setAddrDetailValidAct({ commit }, { payload }) {
    commit("setAddrDetailValidMut", payload);
  },
  setSubmitAct({ commit }, { payload }) {
    commit("setSubmitMut", payload);
  }
};
export const signUpValidActList = [
  "setBusinessTypeValidAct",
  "setBizNumValidAct",
  "setPwValidAct",
  "setMartNameValidAct",
  "setTelValidAct",
  "setStimeValidAct",
  "setEtimeValidAct",
  "setCeonameValidAct",
  "setPhoneValidAct",
  "setImpUidValidAct",
  "setAddrValidAct",
  "setAddrDetailValidAct",
  "setSubmitAct"
];
