import axios from "axios";

export const state = {
    barcodeList: [],
    selectedBarcode: {
        barcode: ''
    }
}

export const getters = {}

//mutations == vue component method
export const mutations = {
    setBarcodeList(state, payload) {
        state.barcodeList = payload
    },

    setBarcodeData(state, val) {
        state.selectedBarcode = val
    }
}

export const actions = {
    callBarcodeListSrch(state, val) {
        state.commit('setBarcodeList', [])

        const tmpNum = state.rootState.loggedInfo.logged_info.biznum
        const url = `/api/event/group/product/${tmpNum}?qu=${val}`

        axios.get(url)
            .then((res) => {
                console.log(res.data)

                state.commit('setBarcodeList', res.data)
            })
            .catch(
                err => console.log('Call Srch Res Error :', err)
            )
    }
}

export const modules = {}
